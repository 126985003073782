<template>
  <div class="containerBox">
    <div class="filter">
      <div>
        <el-select
          v-model="params1"
          size="small"
          @change="handleParams1"
          class="marginR-16"
        >
          <el-option
            v-for="item in paramsList1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="params2"
          size="small"
          clearable
          :placeholder="$t('interview')"
        >
          <el-option
            v-for="item in paramsList2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="params1 === '0'"
          >
          </el-option>
        </el-select>
      </div>
      <el-button size="small" type="primary" @click="handleSearch">{{
        $t("search")
      }}</el-button>
    </div>
    <div class="list" v-loading="loading">
      <div v-for="(item, index) in dataList" :key="index" class="item">
        <div class="left">
          <div class="leftTop">
            <span
              class="job"
              @click="goJob(item)"
              :title="item.name | textFormat"
              >{{ item.name | textFormat }}</span
            >
            <span class="money" v-if="item.salary_lowest != 0"
              >{{ item.salary_lowest | textFormat }}K -
              {{ item.salary_highest | textFormat }}K</span
            >
            <span v-else class="money">{{ $t("salaryNegotiable") }}</span>
          </div>
          <div class="companyInfo">
            <div class="companyNane" @click="goJob(item)">
              {{
                item.company_name_en | priorFormat(item.company_name_zh, LOCALE)
              }}
            </div>
            <div class="leader">
              <span
                class="name"
                :title="
                  item.person_name_en | priorFormat(item.person_name_zh, LOCALE)
                "
                >{{
                  item.person_name_en | priorFormat(item.person_name_zh, LOCALE)
                }}</span
              >
              |
              <span
                class="jobTitle"
                :title="
                  item.person_job_title_en
                    | priorFormat(item.person_job_title_zh, LOCALE)
                "
                >{{
                  item.person_job_title_en
                    | priorFormat(item.person_job_title_zh, LOCALE)
                }}</span
              >
            </div>
            <div
              class="status"
              :class="{ statusEn: LOCALE == 'en' }"
              @click="handleOperation(item)"
            >
              <!--待处理-->
              <div v-if="item.status === 0" class="status4">
                {{ $t("viewThe") }}
              </div>
              <div v-if="item.status === 1" class="status4">
                {{ $t("haveToSee") }}
              </div>
              <div v-if="item.status === 2" class="status1">
                {{ $t("pending") }}
              </div>
              <div v-if="item.status === 3" class="status1">
                {{ $t("waitingConfirm") }}
              </div>
              <div v-if="item.status === 6 && item.isEnd !== 3" class="status3">
                {{ $t("forInterview") }}
              </div>
              <div v-if="item.status === 6 && item.isEnd === 3" class="status3">
                {{ $t("interviewEnd") }}
              </div>
              <div v-if="item.status === 4" class="status2">
                {{ $t("rejected") }}
              </div>
              <div v-if="item.status === 5" class="status2">
                {{ $t("rejectedYet") }}
              </div>
              <div v-if="item.status === 8" class="status2">
                {{ $t("cencelInterview") }}
              </div>
              <div v-if="item.status === 7" class="status3">
                {{ $t("cancelled") }}
              </div>
              <div v-if="item.status === 9" class="status4">
                {{ $t("accepted") }}
              </div>
              <div v-if="item.status === 10" class="status4">
                {{ $t("Denied") }}
              </div>
              <div v-if="item.status === 11" class="status4">
                {{ $t("closePosition") }}
              </div>
            </div>
            <div
              v-if="(item.status !== 0 || item.status !== 1) && item.start_time"
              class="date"
            >
              {{ item.start_time | secondFormat("LLL") }} -
              {{ item.end_time | secondFormat("T") }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="btns">
            <orderTalk
              v-if="
                item.status === 0 ||
                item.status === 1 ||
                item.status === 4 ||
                item.status === 5 ||
                item.status === 7 ||
                item.status === 8
              "
              :in_companyId="item.company_id"
              :in_userId="item.person_in_charge"
              :position_id="item.position_id"
              :position_apply_id="item.job_position_apply_id"
              @successChange="getDataList"
              :isApplicants="true"
              invitationType="1"
            />
            <el-button
              size="mini"
              round
              v-if="item.status === 2"
              :loading="item.refusedLoading"
              class="btn2"
              @click="handleRefused(item)"
              >{{ $t("refuse") }}</el-button
            >
            <el-button
              type="primary"
              size="mini"
              round
              v-if="item.status === 2"
              :loading="item.acceptLoading"
              class="btn1"
              @click="checkAccept(item)"
              >{{ $t("acceptInvite") }}</el-button
            >
            <cencenlInterview
              v-if="item.status === 6 && item.isEnd !== 3"
              :chat_type="item.chat_type"
              :position_id="item.job_position_apply_id"
              :one2oneChatId="item.chat_id"
              @successChange="getDataList"
              style="margin-left: 8px"
            />
            <joinInterview
              v-if="item.status === 6 && item.isEnd !== 3"
              :channel_id="item.live_id"
              :chat_type="item.chat_type"
              :start_time="item.start_time"
              :end_time="item.end_time"
            ></joinInterview>
            <!--            <el-image-->
            <!--              v-if="item.status === 6"-->
            <!--              :src="idcodeImg"-->
            <!--              style="width: 30px; margin-right: 8px"-->
            <!--              class="cursor"-->
            <!--              @click="isIdCodeDialog(item)"-->
            <!--            ></el-image>-->
          </div>
        </div>
      </div>
      <noDataImg v-if="dataList.length === 0"></noDataImg>
    </div>
    <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="count"
        background
        :hide-on-single-page="true"
        :current-page.sync="start"
        :page-size="7"
        @current-change="currentChange"
      />
    </div>
    <operationDialog
      :visible.sync="operationDialog"
      :data="currentData"
      @handleDaily_chat="getDataList"
    />
    <idCode
      ref="idCode"
      :channel_id="idCode.live_id"
      :chat_type="idCode.chat_type"
    ></idCode>
  </div>
</template>

<script>
import operationDialog from "~bac/components/talent/operationDialog";
import orderTalk from '~hbs/components/button/orderTalk';
import cencenlInterview from "~bac/components/button/cencenlInterview";
import joinInterview from "~bac/components/button/joinInterview";
import idCode from "~/baseComponents/idCode";

export default {
  name: "myApply",
  metaInfo() {
    return {
      title: this.$t("myJob"),
    };
  },
  components: {
    operationDialog,
    orderTalk,
    cencenlInterview,
    joinInterview,
    idCode,
  },
  filters: {
    statusShow(status, item) {
      let str = "";
      switch (status) {
        case 0:
          str = this.$t("successDelivering"); //this.$t('')
          break;
        case 1:
          str = this.$t("accessHave");
          break;
        case 2:
          str = this.$t("beenInterview");
          break;
        case 3:
          str = this.$t("appliedInterview");
          break;
        case 4:
          str = this.$t("rejected");
          break;
        case 5:
          str = this.$t("rejectedYet");
          break;
        case 6:
          if (item.isEnd !== 3) {
            str = this.$t("waitingInterview");
          } else {
            str = this.$t("havemeetingEnd");
          }
          break;
        case 7:
          str = this.$t("cancelled");
          break;
        case 8:
          str = this.$t("otheCancel");
          break;
        case 9:
          str = this.$t("hire");
          break;
        case 10:
          str = this.$t("failedInterview");
          break;
        case 11:
          str = this.$t("closePosition");
          break;
      }
      return str;
    },
  },
  data() {
    return {
      loading: false,
      params1: "",
      paramsList1: [
        {
          value: "",
          label: this.$t("all"),
        },
        {
          value: "1",
          label: this.$t("accessHave"),
        },
        {
          value: "0",
          label: this.$t("accessNo"),
        },
      ],
      params2: "",
      paramsList2: [
        {
          value: "2",
          label: this.$t("confirmed"),
        },
        {
          value: "3",
          label: this.$t("rejected"),
        },
        {
          value: "4",
          label: this.$t("forInterview"),
        },
        {
          value: "5",
          label: this.$t("cancelInterview"),
        },
        {
          value: "6",
          label: this.$t("interviewPass"),
        },
        {
          value: "7",
          label: this.$t("failedInterview"),
        },
      ],
      dataList: [],
      operationDialog: false,
      currentData: {},
      count: null,
      start: 1,
      idCode: {
        live_id: null,
        chat_type: null,
      },
      idcodeImg: require("~bac/assets/img/idcode1.png"),
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        user_id: this.USER_ID,
        status: this.params1,
      };
      if (this.params2) {
        params.status = this.params2;
      }
      params.start = this.start - 1;
      params.limit = 7;
      params.meeting_id = this.MEETING_ID;
      let res = await this.$store.dispatch(
        "baseConsole/talent_my_applylist",
        params
      );
      this.loading = false;
      if (res.success) {
        res.data.forEach((item) => {
          item.refusedLoading = false;
          item.acceptLoading = false;
        });
        this.dataList = res.data;
        this.count = res.count;
        let timer = new Date();
        let nowTime = this.$moment(timer).unix();
        this.dataList.forEach((item) => {
          if (nowTime < item.start_time) {
            // 未开始
            item.isEnd = 1;
          } else if (item.start_time < nowTime && item.end_time > nowTime) {
            // 进行中
            item.isEnd = 2;
          } else if (nowTime > item.end_time) {
            // 结束了
            item.isEnd = 3;
          } else {
          }
        });
      }
    },
    handleSearch() {
      this.start = 1;
      this.getDataList();
    },
    handleParams1() {
      this.params2 = "";
    },
    handleOperation(item) {
      if (
        item.status === 0 ||
        item.status === 1 ||
        item.status === 9 ||
        item.status === 10 ||
        item.status === 11
      )
        return;
      // 目前只做面试邀请，待处理 2   和已取消 7
      this.currentData = Object.assign({}, item);
      this.operationDialog = true;
    },
    async handleRefused(item) {
      item.refusedLoading = true;
      let params = {
        meeting_type: item.chat_type,
        status: 3,
        position_apply_id: item.job_position_apply_id,
        meeting_id: 60,
        one2one_chat_id: item.chat_id,
        position_apply_status: 4,
        invitation_type: 1,
      };
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      item.refusedLoading = false;
      if (res.success && res.info) {
        this.$message.success(this.$t("interviewRejection"));
        this.getDataList();
      }
    },
    // 判断之前同一时间段有没有接受邀请
    async checkAccept(item) {
      let params = {
        // meeting_id: 60,
        meeting_status: item.chat_type,
        user_type: 1,
        invite_status: 2,
        user_id: this.USER_INFO.id,
        position_id: item.position_id,
        in_user_id: item.person_in_charge,
        daily_source: 2,
      };
      if (item.chat_type == 1) {
        params.agenda_id = item.agenda_id;
      } else {
        params.start_time = item.start_time;
        params.end_time = item.end_time;
      }
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_order_status",
        params
      );
      if (res.success) {
        // 说明之前有接受过
        if (res.data.status === 2) {
          // const h = this.$createElement;
          // const newDatas = [];
          // newDatas.push(h("span", null, "该时间段您已预约"));
          // // 等于1的时候，返回的是对象
          // newDatas.push(
          //   h("span", { style: "color: #1890FF" }, res.data.info.name)
          // );
          // newDatas.push(h("span", null, "职位面试，是否接受该面试邀请！"));
          // newDatas.push(
          //   h("div", { style: "color: #1890FF; height: 10px" }, "")
          // );
          // newDatas.push(
          //   h(
          //     "div",
          //     { style: "color: #666666; font-size: 12px" },
          //     "备注：同一时间内只能参加一场面试，请您合理安排面试时间。"
          //   )
          // );
          // newDatas.push(
          //   h("div", { style: "color: #1890FF; height: 20px" }, "")
          // );
          // this.$confirm("", {
          //   message: h("div", null, newDatas),
          //   confirmButtonText: "接受邀请",
          //   cancelButtonText: "取消",
          //   showClose: false,
          //   customClass: "acceptConfirm",
          // })
          //   .then(() => {
          //     this.handleAccept(item);
          //   })
          //   .catch(() => {});
          this.$alert(this.$t("interviewPeriodCencel"), "", {
            confirmButtonText: this.$t("Confirm"),
            center: true,
            callback: (action) => {},
          });
        } else {
          this.handleAccept(item);
        }
      }
    },
    // 接受邀请
    async handleAccept(item) {
      item.acceptLoading = true;
      let params = {
        meeting_type: item.chat_type,
        status: 2,
        position_apply_id: item.job_position_apply_id,
        meeting_id: 60,
        one2one_chat_id: item.chat_id,
        position_apply_status: 6,
        invitation_type: 1,
      };
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "baseConsole/talent_handleDaily_chat",
        params
      );
      item.acceptLoading = false;
      if (res.success) {
        if (typeof res.info === "string") {
          this.$message.warning(res.info);
        } else {
          this.$message.success(this.$t("inviteSuccess"));
          this.getDataList();
        }
      } else {
        this.$message.error(this.$t("inviteError"));
      }
    },
    geCompany(item) {
      this.$router.push({
        path: "/companyList/booth",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
            booth_id: item.meeting_booth_id,
          }),
        },
      });
    },
    goJob(item) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.position_id,
          }),
        },
      });
    },
    currentChange(index) {
      this.start = index;
      this.getDataList();
    },
    // 二维码
    isIdCodeDialog(item) {
      this.idCode.live_id = item.live_id;
      this.idCode.chat_type = item.chat_type;
      this.$nextTick(() => {
        this.$refs.idCode.isIdCodeDialog();
      });
    },
  },
};
</script>

<style scoped lang="less">
.containerBox {
  .filter {
    padding: 14px 10px 0;
    display: flex;
    justify-content: space-between;
    .marginR-16 {
      margin-right: 16px;
    }
  }
  .list {
    position: relative;
    height: 616px;
    padding: 32px 10px 0;
    .item {
      padding-left: 30px;
      height: 77px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e8e8e8;
      .left {
        .leftTop {
          display: flex;
          .job {
            cursor: pointer;
            margin-right: 16px;
            font-weight: 800;
            color: #1890ff;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .money {
            font-weight: 400;
            color: #ff2b0d;
          }
        }

        .companyInfo {
          margin-top: 4px;
          display: flex;
          justify-content: center;
          .companyNane {
            cursor: pointer;
            font-weight: 400;
            color: #666666;
            width: 220px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .leader {
            font-weight: bold;
            color: #333333;
            margin-right: 24px;
            display: flex;
            width: 200px;
            .name {
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 10px;
            }
            .jobTitle {
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 10px;
            }
          }
          .status {
            font-size: 12px;
            cursor: pointer;
            margin-right: 17px;
            width: 100px;
            div {
              padding: 2px 12px;
              border-radius: 10px;
              display: inline-block;
            }
            .status1 {
              background: #f8ae34;
              color: #ffffff;
            }
            .status2 {
              background: #ff2b0d;
              color: #ffffff;
            }
            .status3 {
              background: #e9e9e9;
              color: #415058;
            }
            .status4 {
              background: #c8cdd0;
              color: #666666;
              cursor: default;
            }
          }
          .statusEn {
            width: 120px;
          }
          .date {
            font-size: 12px;
            color: #999999;
            display: flex;
            align-items: center;
          }
        }
      }
      .right {
        .btns {
          display: flex;
          flex-direction: row-reverse;
        }
        .btn2 {
          margin-left: 8px;
        }
        .status {
          color: #999999;
          margin-top: 4px;
          text-align: right;
          padding-right: 4px;
        }
      }
    }
    .item:last-child {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .pagination {
    margin: 20px auto 0px;
    display: flex;
    flex-direction: row-reverse;
  }
}

.title {
  padding: 0 40px;
  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>
<style>
.acceptConfirm {
  width: 480px !important;
}
</style>